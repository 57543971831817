import logo from '../logo.svg';

const strings = {
    home: {
        header: 'I am Brody Johnstone'
    }
}

const images = {
    home: {
        logo
    }
}

const config = {
    strings,
    images
}

export { config };